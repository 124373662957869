<template>
  <v-card flat class="pa-0 mt-1">
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="8" class="pa-0">
          <h3 v-if="projectDeviationObj.isProjectDeviation" class="pa-1 pl-2">{{$t('message.projects.project')}}: <router-link :to="`/projects/${projectDeviationObj.projectId}`">{{ printProjectName }}</router-link></h3>
        </v-col>
        <v-col cols="4" class="pa-0 text-end pr-5 py-1">
          <v-btn v-if="checklistEnable" @click="goChecklist()" class="primary">{{ $t('message.checklist.title') }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <scroll-list v-if="renderScrollList" id="deviationScrollList" :payload="scrollListPayload"></scroll-list>
  </v-card>
</template>
<script>
import { hostAppApi } from '../../plugins/axios_settings'
import projectMixin from '@/mixins/projectMixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [projectMixin],
  data () {
    return {
      MODULE: '',
      /* for deviation & project deviation */
      projectDeviationObj: {
        isProjectDeviation: false,
        projectId: 0
      },
      listOfStatus: [this.$t('message.deviation.registered'), this.$t('message.deviation.process'), this.$t('message.deviation.closed')],
      scrollListPayload: {
        width: 95,
        loading: false,
        module: '',
        moduleTitle: 'message.layout.deviation',
        addBtnTo: '',
        editHandler: null,
        actionSize: '0', // for delete action
        list: [],
        totalCount: 0,
        items: [{
          value: 'deviation_number',
          class: 'mt-3 pa-0 pa-1',
          cols: '2',
          spanClass: 'body pl-2'
        }, {
          value: '',
          cols: '9',
          class: 'pa-0 pl-5',
          rowClass: 'mt-1',
          subItems: [{
            value: 'title',
            class: 'pa-0',
            cols: '6',
            spanClass: 'body'
          }, {
            value: 'deviation_date',
            class: 'pa-0',
            cols: '6',
            spanClass: 'subtitle'
          }, {
            value: 'deviation_type_text',
            class: 'pa-0 pb-1',
            cols: '6',
            spanClass: 'subtitle',
            isTranslatable: true
          }, {
            value: 'category_name',
            class: 'pa-0',
            cols: '6',
            spanClass: 'subtitle'
          }, {
            value: 'location_name',
            class: 'pa-0',
            cols: '6',
            spanClass: 'subtitle'
          }, {
            value: 'status',
            class: 'pa-0',
            cols: '6',
            spanClass: 'subtitle'
          }]
        }],
        hasDelete: false,
        listenerAdded: false,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      renderScrollList: false,
      listMaxCallLoaded: false,
      checklistEnable: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'scroll-list': () => import('@/components/VirtualScrollList.vue')
  },
  created () {
    this.MODULE = 'deviation'
    if (this.$route.name === 'project_deviation') {
      this.MODULE = 'projectdeviation'
      this.projectDeviationObj.isProjectDeviation = true
      this.projectDeviationObj.projectId = parseInt(this.$route.params.project_id)
      this.getProject(parseInt(this.$route.params.project_id))
    }
    this.scrollListPayload.editHandler = (item) => this.$router.push(this.projectDeviationObj.isProjectDeviation ? `/deviations/${item.id}?project_id=${this.projectDeviationObj.projectId}` : `/deviations/${item.id}`)
    this.scrollListPayload.addBtnTo = this.projectDeviationObj.isProjectDeviation ? `/deviations/add?project_id=${this.projectDeviationObj.projectId}` : '/deviations/add'
  },
  mounted () {
    this.getListHandler()
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        const payload = { url: `deviation/${data.id}?user_id=${parseInt(this.userId)}`, isSingle: true, module: this.MODULE, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
      }
    })
    // Load list on page
    this.$eventBus.$on('loadList', (search) => {
      this.getListHandler(search)
    })
  },
  methods: {
    getListHandler (search) {
      if (search && (search !== this.scrollListPayload.previousSearch)) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: search, totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (!search && this.scrollListPayload.previousSearch) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: '', totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (this.listMaxCallLoaded) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++
      const model = {
        start: (this.scrollListPayload.page * this.scrollListPayload.recordsPerPage),
        length: this.scrollListPayload.recordsPerPage
      }
      let url = `${this.getHostRefApi}deviation?only_items=1&start=${model.start}&length=${model.length}`
      if (this.projectDeviationObj.isProjectDeviation) url += `&project_id=${this.projectDeviationObj.projectId}`
      if (search) url += `&search=${search}`
      hostAppApi.get(url)
        .then(response => {
          this.checklistEnable = response.data.checklist_enable
          let { data, recordsFiltered } = response.data
          const transformData = {
            date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
          }
          data = this.$formatter.formatListModel(data, transformData)
          data = data.map(value => { return { ...value, status: this.listOfStatus[value.status - 1] } })
          this.scrollListPayload.list = [...this.scrollListPayload.list, ...data]
          if (data.length) this.scrollListPayload.totalCount = recordsFiltered
          if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
        }).finally(() => {
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    },
    goChecklist () {
      this.$router.push(`/projects/${this.$route.params.project_id}/checklists?moduleName=${this.$route.name}`)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
